import type { AnyAction } from 'redux'
import type { ThunkAction } from 'redux-thunk'

import { getInitialState } from 'config/reducers'
import type { TAllAction, TAllActionType, TState } from 'config/types'

type TAnyThunkAction<ReturnType = any> = ThunkAction<ReturnType, TState, any, AnyAction>
type TFakeThunkDispatchOptions = { resolveActionType: TAllActionType; rejectActionType?: TAllActionType }
type TPromisedAction<Options extends TFakeThunkDispatchOptions> = TAllAction<Options['resolveActionType']>

/**
 * Execute an thunk action, using a fake dispatch and a fake (empty) state
 *
 * @param thunkAction The thunk action to be executed
 * @param options
 * @param options.resolveActionType If defined fakeThunkDispatch will not return the thunk action return value,
 * but a promise that will resolve with first action dispatched inside the thunk action whose type match this one.
 * @param options.rejectActionType If both options.resolveActionType and options.rejectActionType are defined,
 * and action whose type match options.rejectActionType is dispatched, the returned promise will reject with that action.
 * @returns If getNextAction is set to true,
 * the return type is a promise that resolve with the first action dispatched by the thunk action.
 * If not, the return type is the return type of the thunk action.
 *
 * Ex. This utility is usefull with fetchApi:
 * - if one only want the result, then options should not be set;
 * - if one want the full fetchApiSuccess payload, then it should be set as follow:
 *
 * ```
 *   const fetchSuccessAction = await fakeThunkDispatch(
 *     fetchApi(myQuery()),
 *     {
 *       resolveActionType: ApiActionTypes.FETCH_API_SUCCESS,
 *       rejectActionType: ApiActionTypes.FETCH_API_FAILURE,
 *     },
 *   )
 * ```
 *
 */
export function fakeThunkDispatch<
  Options extends TFakeThunkDispatchOptions | TEmptyObject = TEmptyObject,
  ReturnType = any,
>(
  thunkAction: TAnyThunkAction<ReturnType>,
  options?: Options,
): Options extends TFakeThunkDispatchOptions ? Promise<TPromisedAction<Options>> : ReturnType
export function fakeThunkDispatch<Options extends TFakeThunkDispatchOptions, ReturnType>(
  action: TAnyThunkAction<ReturnType>,
  options?: Options,
): Promise<TPromisedAction<Options>> | ReturnType {
  if (options?.resolveActionType) {
    return new Promise<TPromisedAction<Options>>((resolve, reject) => {
      action(
        (action: AnyAction | TAnyThunkAction) => {
          if ('type' in action && action.type === options.resolveActionType) {
            resolve(action as TPromisedAction<Options>)
          }
          if (options.rejectActionType && 'type' in action && action.type === options.rejectActionType) {
            reject(action as TAllAction<Options['rejectActionType']>)
          }
        },
        getInitialState,
        undefined,
      )
    })
  }
  return action(
    () => {
      /* Empty */
    },
    getInitialState,
    undefined,
  )
}
