import type { GetStaticPropsContext, GetStaticPropsResult } from 'next'
import type { ParsedUrlQuery } from 'querystring'

import { wrapper } from '@hozana/redux/initStore'
import type { TStore, TWrappedGetStaticData, TWrappedGetStaticProps } from '@hozana/redux/types'
import { sanitize } from '@hozana/utils/functions/objects'

import type { TLocale } from 'i18n/constants'
import { STATIC_PAGES_REVALIDATE } from 'routes/constants'

import { selectCommunity } from 'modules/community/selectors'
import type { TIntentionsWidget } from 'modules/intention/types'

import {
  TCommonPageProps,
  TGetCommonPagePropsOptions,
  TGetGetServerPropsProps,
  TServerApiQueries,
  TServerApiQueriesResult,
  TTitleGetter,
  createGetInitialData,
  dispatchTitle,
  getCommonPageProps,
  recordPageMetrics,
} from './common'

export const createGetStaticData =
  <GAQ extends (ctx: GetStaticPropsContext<ParsedUrlQuery>) => TServerApiQueries>(
    getApiQueries: GAQ,
  ): TWrappedGetStaticData<TServerApiQueriesResult<ReturnType<GAQ>>, Parameters<GAQ>[0]['params']> =>
  async (ctx) => {
    const apiQueries = getApiQueries(ctx)

    // Add ssgFetched meta
    const staticApiQueries = Object.entries(apiQueries).reduce(
      (acc, [key, apiQuery]) => ({
        ...acc,
        [key]: { ...apiQuery, query: { ...apiQuery.query, meta: { ...apiQuery.query?.meta, ssgFetched: true } } },
      }),
      {} as ReturnType<GAQ>,
    )

    const props = await createGetInitialData(staticApiQueries)(ctx)

    if (Object.values(props).some((prop) => prop instanceof Error)) {
      return {
        notFound: true,
      }
    }

    return { props }
  }

const getWidgetLocale = <W extends 'community' | 'intentions'>(
  widget: W,
  store: TStore,
  props: { communityId?: number; widget?: TIntentionsWidget },
): string => {
  if (widget === 'intentions') {
    return props.widget?.lang || 'fr'
  }
  return selectCommunity(store.getState(), props.communityId)?.lang || 'fr'
}

type TGetStaticPropsOptions = {
  /** Should be set to 'community' or 'intention' on the widgets */
  widget?: 'community' | 'intentions'
} & TGetCommonPagePropsOptions

type TGSPResult<GDSP extends TWrappedGetStaticProps> = GetStaticPropsResult<
  TCommonPageProps & TGetGetServerPropsProps<GDSP> & Parameters<GDSP>[0]['params']
>

/**
 * ### createGetStaticProps ###
 *
 * Note: revalidate option is handled [in the routes folder](../../../routes/pages.js)
 *
 * @returns a getStaticProps function
 */
export const createGetStaticProps =
  <GDSP extends TWrappedGetStaticProps, Context extends Parameters<GDSP>[0]>(
    pathname: keyof typeof STATIC_PAGES_REVALIDATE,
    titleGetter: TTitleGetter<Context>,
    /** A getStaticProps function for server side props dedicated to a page */
    getDedicatedStaticProps?: GDSP,
    { widget, ...commonPagePropsOptions }: TGetStaticPropsOptions = {},
  ) =>
  async (context: Omit<Context, 'store'>) => {
    recordPageMetrics('getStaticProps', pathname, context.locale as TLocale)

    // wrapper.getStaticProps adds Redux on the server
    const staticProps = await wrapper.getStaticProps(
      (store: TStore) =>
        async (ctx: Omit<Context, 'store'>): Promise<TGSPResult<GDSP>> => {
          const revalidate = STATIC_PAGES_REVALIDATE[pathname] || false
          const contextWithStore = { ...ctx, store }

          // Load data concurrently to go faster
          const [commonPageProps, dedicatedStaticResult] = await Promise.all([
            getCommonPageProps({ ...ctx, pathname }, commonPagePropsOptions),
            (getDedicatedStaticProps ? getDedicatedStaticProps({ ...ctx, store }) : { props: {} }) as ReturnType<GDSP>,
          ])

          if ('notFound' in dedicatedStaticResult || 'redirect' in dedicatedStaticResult) {
            return { ...dedicatedStaticResult, revalidate }
          }

          if (widget && dedicatedStaticResult && 'props' in dedicatedStaticResult) {
            const locale =
              widget && dedicatedStaticResult?.props
                ? getWidgetLocale(widget, store, dedicatedStaticResult?.props)
                : ctx.locale

            return {
              props: {
                ...(await getCommonPageProps({ ...ctx, pathname, locale }, commonPagePropsOptions)),
                ...dedicatedStaticResult?.props,
              },
              revalidate,
            } as TGSPResult<GDSP>
          }

          dispatchTitle(titleGetter, contextWithStore)

          return {
            ...dedicatedStaticResult,
            ...('props' in dedicatedStaticResult && {
              props: {
                ...commonPageProps,
                ...ctx.params,
                ...(dedicatedStaticResult.props as TGetGetServerPropsProps<typeof getDedicatedStaticProps>),
              },
            }),
            revalidate,
          }
        },
    )(context)

    return sanitize(staticProps)
  }
